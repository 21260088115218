import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'

const Service = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="service" lang="ja" />
        <title>{`製品／サービス | ${title}`}</title>
        <meta
          name="description"
          content="お客様のそれぞれのニーズに合わせた製品をご紹介いたします。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <h1>製品・サービス</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>製品・サービス</li>
        </div>
      </div>

      <div className="main-wrapper service-top">
        <div className="row">
          <div className="container top">
            <h4>あたらしいニーズを求めて、わたしたちは今日も現場へ。</h4>

            <table className="service-map pc-only">
              <tr>
                <td>
                  <div className="title">
                    チケットレス
                    <br />
                    ゲートシステム
                  </div>
                  <div
                    className="thumbnail"
                    style={{
                      backgroundImage: `url('/assets/images/common/service-bg-ticketless.png')`,
                    }}
                  ></div>
                  <div className="desc">
                    駐車券をなくすことによりスムーズな入出庫が可能になります。出口で渋滞になりません。
                  </div>
                  <div className="table-title product">製品</div>
                  <div className="button wide long row1">
                    <Link to={`/service/internet/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-netdeal-w.png"
                        alt=""
                      />
                      <div className="service-name">ネットワーク対応精算機</div>
                    </Link>
                    <Link to={`/service/remote/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-remotecontrol-w.png"
                        alt=""
                      />
                      <div className="service-name">遠隔管理システム</div>
                    </Link>
                  </div>
                  <div className="button mini row2 inactive">
                    <img
                      className="icon"
                      src="/assets/images/common/icon-gatesystem-w.png"
                      alt=""
                    />
                    <div className="service-name">
                      チケットレス
                      <br />
                      ゲートシステム
                    </div>
                  </div>
                  <div className="table-title service">サービス</div>
                  <div className="button wide long row3">
                    <Link to={`/service/qtnet/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-qtnet-w.png"
                        alt=""
                      />
                      <div className="service-name">QT-net</div>
                    </Link>
                  </div>
                  <div className="button wide medium row4">
                    <Link to={`/service/smartpay/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-smartpay-w.png"
                        alt=""
                      />
                      <div className="service-name">Smart Pay</div>
                    </Link>
                  </div>
                  <div className="button wide long row5">
                    <Link to={`/service/support/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-support-w.png"
                        alt=""
                      />
                      <div className="service-name">管理サポート</div>
                    </Link>
                  </div>
                </td>

                <td>
                  <div className="title">
                    ロックレス駐車場
                    <br />
                    <span>（ナンバー認識システム）</span>
                  </div>
                  <div
                    className="thumbnail"
                    style={{
                      backgroundImage: `url('/assets/images/common/service-bg-lockless.png')`,
                    }}
                  ></div>
                  <div className="desc">
                    ナンバー認識カメラシステムで、ロックレスをはじめて実現。
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row1"></div>
                  <div className="button mini row2">
                    <Link to={`/service/lockless/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-lockless-w.png"
                        alt=""
                      />
                      <div className="service-name">
                        ナンバー認識
                        <br />
                        システム
                      </div>
                    </Link>
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row3"></div>
                  <div className="button branc row4"></div>
                  <div className="button branc row5"></div>
                </td>

                <td>
                  <div className="title single">ゲート式駐車場</div>
                  <div
                    className="thumbnail"
                    style={{
                      backgroundImage: `url('/assets/images/common/service-bg-gate.png')`,
                    }}
                  ></div>
                  <div className="desc">
                    中〜大規模駐車場に適した、集中管理システム。
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row1"></div>
                  <div className="button mini row2">
                    <Link to={`/service/gate/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-gatesystem-w.png"
                        alt=""
                      />
                      <div className="service-name">
                        ゲート式駐車場
                        <br />
                        システム
                      </div>
                    </Link>
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row3"></div>
                  <div className="button branc row4"></div>
                  <div className="button branc row5"></div>
                </td>

                <td>
                  <div className="title">
                    バイクロック
                    <br />
                    バイクゲート
                  </div>
                  <div
                    className="thumbnail"
                    style={{
                      backgroundImage: `url('/assets/images/common/service-bg-bike.png')`,
                    }}
                  ></div>
                  <div className="desc">
                    バイクの路上駐車に対応する、省スペースで信頼性の高いシステム。
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row1"></div>
                  <div className="button mini row2">
                    <Link to={`/service/bike/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-bikelock-w.png"
                        alt=""
                      />
                      <div className="service-name">
                        バイク用
                        <br />
                        ロック板ユニット
                      </div>
                    </Link>
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row3"></div>
                  <div className="button branc row4"></div>
                  <div className="button branc row5"></div>
                </td>

                <td>
                  <div className="title single">ロック板駐車場</div>
                  <div
                    className="thumbnail"
                    style={{
                      backgroundImage: `url('/assets/images/common/service-bg-lock.png')`,
                    }}
                  ></div>
                  <div className="desc">
                    昇降フラップ式のロック板で、それぞれの車室をしっかり管理。
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row1"></div>
                  <div className="button mini row2">
                    <Link to={`/service/unit/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-lockunit-w.png"
                        alt=""
                      />
                      <div className="service-name">
                        ロック板
                        <br />
                        ユニット
                      </div>
                    </Link>
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row3"></div>
                  <div className="button branc row4"></div>
                  <div className="button branc row5"></div>
                </td>

                <td>
                  <div className="title">
                    ビル・マンション
                    <br />
                    店舗
                  </div>
                  <div
                    className="thumbnail"
                    style={{
                      backgroundImage: `url('/assets/images/common/service-bg-building.png')`,
                    }}
                  ></div>
                  <div className="desc">
                    アイテックの防犯カメラはインターネット対応。遠隔でリアルタイム監視や過去画像の確認ができます。
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row1"></div>
                  <div className="button mini row2 single">
                    <Link to={`/service/camera/`}>
                      <img
                        className="icon"
                        src="/assets/images/common/icon-surveillancecamera-w.png"
                        alt=""
                      />
                      <div className="service-name">防犯カメラ</div>
                    </Link>
                  </div>
                  <div className="table-title"></div>
                  <div className="button branc row3"></div>
                  <div className="button branc row4"></div>
                  <div className="button branc row5"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <section className="service">
        <div className="row">
          <div className="h-wrapper">
            <h2>製品・サービス</h2>
          </div>
        </div>

        <div className="row pannel pc-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/internet/`}>
                <img src="/assets/images/common/icon-netdeal.png" alt="" />
                インターネット対応精算機
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/remote/`}>
                <img
                  src="/assets/images/common/icon-remotecontrol.png"
                  alt=""
                />
                遠隔管理システム
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/lockless/`}>
                <img src="/assets/images/common/icon-lockless.png" alt="" />
                ナンバー認識システム「ロックレス」
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel pc-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/gate/`}>
                <img src="/assets/images/common/icon-gatesystem.png" alt="" />
                ゲート式駐車場管理システム
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/unit/`}>
                <img src="/assets/images/common/icon-lockunit.png" alt="" />
                ロック板ユニット
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/bike/`}>
                <img src="/assets/images/common/icon-bikelock.png" alt="" />
                バイク用ロック板ユニット
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel pc-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/bicycle/`}>
                <img src="/assets/images/common/icon-bicycle.png" alt="" />
                駐輪管理システム
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/camera/`}>
                <img
                  src="/assets/images/common/icon-surveillancecamera.png"
                  alt=""
                />
                防犯カメラ
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/qtnetservice/`}>
                <img src="/assets/images/common/icon-qtnet.png" alt="" />
                QT-net サービス
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel pc-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/qtnet/`}>
                <img src="/assets/images/common/icon-qtnet.png" alt="" />
                QT-net
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/smartpay/`}>
                <img src="/assets/images/common/icon-smartpay.png" alt="" />
                SmartPay
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/support/`}>
                <img src="/assets/images/common/icon-support.png" alt="" />
                管理サポート
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel pc-only">
          <div className="container">
            <div className="inner">
              <a
                href="/assets/files/itc-product-maintenance.pdf"
                target="_blank"
              >
                <img src="/assets/images/common/icon-setting.png" alt="" />
                生産終了及び保守期限一覧表
              </a>
            </div>
            <div className="inner blanc"></div>
            <div className="inner blanc"></div>
          </div>
        </div>

        <div className="row pannel sp-only">
          <div className="container">
            <div className="inner multi">
              <Link to={`/service/internet/`}>
                <img src="/assets/images/common/icon-netdeal.png" alt="" />
                インターネット
                <br />
                対応精算機
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/remote/`}>
                <img
                  src="/assets/images/common/icon-remotecontrol.png"
                  alt=""
                />
                遠隔管理システム
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel sp-only">
          <div className="container">
            <div className="inner multi">
              <Link to={`/service/lockless/`}>
                <img src="/assets/images/common/icon-lockless.png" alt="" />
                ナンバー認識システム
                <br />
                「ロックレス」
              </Link>
            </div>{' '}
            <div className="inner multi">
              <Link to={`/service/gate/`}>
                <img src="/assets/images/common/icon-gatesystem.png" alt="" />
                ゲート式駐車場
                <br />
                管理システム
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel sp-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/unit/`}>
                <img src="/assets/images/common/icon-lockunit.png" alt="" />
                ロック板ユニット
              </Link>
            </div>
            <div className="inner multi">
              <Link to={`/service/bike/`}>
                <img src="/assets/images/common/icon-bikelock.png" alt="" />
                バイク用
                <br />
                ロック板ユニット
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel sp-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/camera/`}>
                <img
                  src="/assets/images/common/icon-surveillancecamera.png"
                  alt=""
                />
                防犯カメラ
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/bicycle/`}>
                <img src="/assets/images/common/icon-bicycle.png" alt="" />
                駐輪管理システム
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel sp-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/qtnetservice/`}>
                <img src="/assets/images/common/icon-qtnet.png" alt="" />
                QT-net サービス案内
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/qtnet/`}>
                <img src="/assets/images/common/icon-qtnet.png" alt="" />
                QT-net
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel sp-only">
          <div className="container">
            <div className="inner">
              <Link to={`/service/smartpay/`}>
                <img src="/assets/images/common/icon-smartpay.png" alt="" />
                SmartPay
              </Link>
            </div>
            <div className="inner">
              <Link to={`/service/support/`}>
                <img src="/assets/images/common/icon-support.png" alt="" />
                管理サポート
              </Link>
            </div>
          </div>
        </div>
        <div className="row pannel sp-only">
          <div className="container">
            <div className="inner">
              <a
                href="/assets/files/itc-product-maintenance.pdf"
                target="_blank"
              >
                <img src="/assets/images/common/icon-setting.png" alt="" />
                生産終了及び保守期限一覧表
              </a>
            </div>
            <div className="inner blanc"></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Service
